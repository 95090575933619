import { useEffect } from 'react'

import Script from 'next/script'

import { useAuthState } from '@b-stock/bstock-next'

type Props = {
  apiKey: string
}

const Pendo = ({ apiKey }: Props) => {
  const authState = useAuthState()
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowProxy = window as unknown as any
    const initArg: Record<string, unknown> = {}

    if (authState) {
      initArg.visitor = {
        id: authState.person._id,
        name:
          `${authState.person.firstName}` +
          ' ' +
          `${authState.person.lastName}`,
        email: authState.person.email,
      }
      initArg.account = {
        id: authState.person.accountId,
      }
    }

    if (windowProxy.pendo?.initialize) {
      // TODO: fix types for pendo library
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      windowProxy.pendo.initialize(initArg)
    } else {
      windowProxy.pendo = {
        _q: [['initialize', initArg]],
      }
    }
  }, [authState])

  return <Script src={`https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`} />
}

export default Pendo
